import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import UserContext from './UserContext';
import SignIn from './pages/SignIn';
import Home from './pages/Home';
import CreateDeck from './pages/CreateDeck';
import CardSearch from './pages/CardSearch';
import ModifyDeck from './pages/ModifyDeck';
import SignUp from './pages/SignUp';

const ProtectedRoute = ({ element, user }) => {
  if (!user) {
    return <Navigate to="/" />;
  }
  return element;
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const getLoggedInUserData = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData || null;
  };

  useEffect(() => {
    const loggedInUserData = getLoggedInUserData();
    setUser(loggedInUserData);
    console.log('User data set:', loggedInUserData);
    setLoading(false); // Set loading state to false after setting user state
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Render a loading indicator while waiting for user state
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <div className="App">
        <header className="App-header"></header>
      </div>
      <AuthProvider>
        <Router>
          <Routes>
            <Route index path="/" element={<SignIn />} />
            <Route path="/home" element={<ProtectedRoute element={<Home />} user={user} />} />
            <Route path="/create-deck" element={<ProtectedRoute element={<CreateDeck />} user={user} />} />
            <Route path="/card-search" element={<ProtectedRoute element={<CardSearch />} user={user} />} />
            <Route path="/modify-deck" element={<ProtectedRoute element={<ModifyDeck userId={user ? user._id : null} />} user={user} />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </Router>
      </AuthProvider>
    </UserContext.Provider>
  );
};

export default App;
